<template>
  <div class="agreement_xy">
    <div v-html="protocol"></div>
  </div>
</template>

<script>
import { text } from '@/components/Modal/ProtocolModal/protocolText'

export default {
  name: '',
  data() {
    return {
      protocol: '',
    }
  },
  created() {
    this.protocol = text.platform
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.agreement_xy {
  background: #ffffff;
  div{
    width: 1200px;
    line-height: 30px;
    margin: 0 auto;
    ::v-deep p:nth-child(2) {
      text-align: center;
      font-weight: 700;
    }
  }
}
</style>
